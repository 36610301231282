import { FILTERS_EQUAL, PROMOTIONAL_PLANNING_PROMO_FIXED_TERMS } from "@kraftheinz/common";
import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { staticClass: "modal-add-fixedterm", attrs: { "last-modified-by-name": true, "title": "Edit Fixed Terms", "width": 600 } }, [_c("select-input", { key: "plLineId", attrs: { "label": "Discount", "placeholder": "Discount", "reference": "promotion-planning.common.pllines", "source": "id", "rules": "required", "source-label": function(val) {
    return _vm.generateLabel(val, ["code", "description"]);
  }, "span": 24 } }), _c("select-input", { key: "customerId", attrs: { "label": "Key Account", "on-select-change": _vm.onCustomerChange, "placeholder": "Key Account", "reference": "promotion-planning.common.customers", "source": "id", "source-label": function(val) {
    return _vm.generateLabel(val, ["region", "keyAccountCode", "keyAccount"]);
  }, "rules": "required", "sort-function": _vm.sortNationalAccount, "span": 24 } }), _c("select-input", { key: "category", attrs: { "label": "Category", "on-select-change": _vm.onCategoryChange, "placeholder": "Category", "reference": "promotion-planning.common.categories", "rules": "required", "source": "code", "source-label": function(val) {
    return _vm.generateLabel(val, ["code", "description"]);
  }, "span": 12 } }), _c("select-input", { key: "subCategory", attrs: { "advanced-filters": _vm.subcategoryFilters, "fetch-options-on-filter": true, "label": "Subcategory", "on-select-change": _vm.onSubcategoryChange, "placeholder": "Subcategory", "reference": "promotion-planning.common.sub-categories", "rules": "required", "source": "code", "source-label": function(val) {
    return _vm.generateLabel(val, ["code", "description"]);
  }, "span": 12 } }), _c("select-input", { key: "ppgId", attrs: { "advanced-filters": _vm.ppgFilters, "fetch-options-on-filter": true, "label": "PPG Code", "placeholder": "PPG Code", "queries": _vm.ppgQueries, "reference": "promotion-planning.common.products", "rules": "required", "source": "id", "source-label": function(val) {
    return _vm.generateLabel(val, ["code", "description"]);
  }, "span": 24 } }), _c("text-input", { key: "lsv", attrs: { "label": "LSV %", "class-name-component": "margin-bottom", "placeholder": "LSV %", "max-length": 6, "class-name": "margin-input", "rules": "number|between:0,100|decimal:3,2", "span": 12 } }), _c("text-input", { key: "isv", attrs: { "label": "ISV %", "class-name-component": "margin-bottom", "class-name": "margin-input", "placeholder": "ISV", "max-length": 6, "rules": "number|between:0,100|decimal:3,2", "span": 12 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditFixedTerm",
  inject: ["crud"],
  mixins: [PromoUtils],
  data() {
    const { id } = this.$route.params;
    return {
      fixedtermId: id,
      selectedCategoryId: null,
      selectedCustomerId: null,
      selectedSubcategoryId: null
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity() || {};
    },
    subcategoryFilters() {
      if (!this.selectedCategoryId)
        return null;
      return [
        [
          "CategoryId",
          { operator: FILTERS_EQUAL, value: this.selectedCategoryId }
        ]
      ];
    },
    ppgFilters() {
      if (!this.selectedCustomerId || !this.selectedSubcategoryId)
        return null;
      return [
        [
          "CustomerId",
          { operator: FILTERS_EQUAL, value: this.selectedCustomerId }
        ],
        [
          "SubCategory",
          { operator: FILTERS_EQUAL, value: this.selectedSubcategoryId }
        ]
      ];
    },
    ppgQueries() {
      return [
        ["Include", true],
        ["distinct", true]
      ];
    }
  },
  watch: {
    entity(newVal) {
      this.selectedCustomerId = newVal.customerId;
      this.selectedCategoryId = newVal.category;
      this.selectedSubcategoryId = newVal.subCategory;
    }
  },
  methods: {
    onCategoryChange(value) {
      this.selectedCategoryId = value;
    },
    onCustomerChange(value) {
      this.selectedCustomerId = value;
    },
    onSubcategoryChange(value) {
      this.selectedSubcategoryId = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditFixedTerm = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-fixed-term" }, [_c("resource", { attrs: { "name": "promotion-planning.common.pllines", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.common.categories", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.sub-categories", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.products", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.fixedterms", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/fixed-terms", "page": _vm.page } }, [_c("edit-fixed-term")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditFixedTerm
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_FIXED_TERMS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
